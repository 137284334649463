.research-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.research-content{
    position: relative;
    width: 80vw;
    height: 75vh;
    display: flex;
    justify-content: flex-start;
}

.research-image{
    height: 30vw;
    border-radius: 3vw;
}

.research-text-box{
    background-color: #7BBEC3;
    color: white;
    font-size: 3vw;
    font-weight: bold;
    border: 0.3vw solid white;
    border-radius: 3vw;
    padding: 3vw 2vw;
    position: absolute;
    left: 30vw;
    top: 15vw;
    cursor: pointer;
   
    &:hover .research-hover-div {
        opacity: 1;
        pointer-events: all;
      }

}

.research-hover-div{
    background-color: #7BBEC3;
    color: white;
    border: 0.3vw solid white;
    border-radius: 3vw;
    padding-top: 2vw;
    padding-bottom: 3vw;
    width: 25vw;
    position: absolute;
    left: 25%;
    top: -60%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}



.research-hover-div-image{
    width: 80%;
}

.research-watch-video-button{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 1.2vw;
    border-radius: 2vw;
    padding: 0.8vw;
    position: absolute;
    width: 90%;
    top: 17vw;
    left: 0.4vw;
}

