.new-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin: 0 auto;
    gap: 2vw;
    margin-top: 4vw;
}

.new-manu-container{
    width: 25vw;
    position: relative;
    &:hover{
        .new-manu-hover{
            pointer-events: all;
            opacity: 1;
        }
    }
}

.new-manu-image{
    width: 100%;
    cursor: pointer;
}

.new-cost-container{
    width: 36vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 2vw;
    font-weight: bold;
    color: white;
    cursor: pointer;

    &:hover{
        .new-cost-hover{
        opacity: 1;
        pointer-events: all;
        }
    }
}

.new-top-arrow{
    width: 2vw;
}

.new-cost-image{
    width: 100%;
    margin-top: 5vw;
}

.new-hospital-container{
    width: 25vw;
    position: relative;
    &:hover{
        .new-hospital-hover{
            pointer-events: all;
            opacity: 1;
        }
    }
}

.new-hospital-image{
    width: 100%;
}

.new-cost-hover{
    position: absolute;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0,0.2);
    padding: 1vw;
    padding-bottom: 3vw;
    border-radius: 3vw;
    border: 0.1vw solid white;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}


.new-cost-hover-box-1{
    position: relative;
    width: 23vw;
}

.new-cost-hover-box-2{
    position: relative;
    margin-top: 5vw;
    width: 23vw;
}

.new-cost-hover-box-button-1{
    background-color: white;
    border: 0.2vw solid black;
    border-radius: 3vw;
    color: black;
    font-size: 0.8vw;
    padding: 0.2vw;
    width: 14vw;
    position: absolute;
    left: 7.5vw;
    top: 11vw;
    cursor: pointer;
}



.new-cost-hover-box-img-1{
    width: 100%;
}

.new-cost-hover-box-img-2{
    width: 100%;
}



.new-cost-hover-box-button-2{
    background-color: white;
    border: 0.2vw solid black;
    border-radius: 3vw;
    color: black;
    font-size: 0.8vw;
    padding: 0.2vw;
    width: 14vw;
    position: absolute;
    left: 7.5vw;
    top: 11vw;
    cursor: pointer;
}


.new-hospital-hover{
    width: 25vw;
    position: absolute;
    left:0vw;
    top: -5vw;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.new-manu-hover{
    position: absolute;
    width: 20vw;
    top: 5vw;
    left: 3vw;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.new-manu-hover-box{
    border: 0.3vw solid white;
    background-color: #7BBEC3;
    padding: 1vw;
    padding-bottom: 4vw;
    border-radius: 2vw;
    cursor: pointer;
}

.new-manu-hover-image{
    width: 100%;
}

.new-manu-hover-button{
    background-color: white;
    border: 0.2vw solid black;
    border-radius: 3vw;
    color: black;
    font-size: 0.8vw;
    font-weight: bold;
    padding: 0.2vw;
    width: 14vw;
    position: absolute;
    top: 13.5vw;
    left: 2.5vw;
    cursor: pointer;
}

