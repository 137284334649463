.content-handler{
    width: 0px;
    height: 0px;
}





.top-level-text{
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    z-index: -1;
    position: relative;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  .planet-text-big{
    width: 185px;
    height: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
  
  }

  .planet-text-big span{
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  .planet-text-medium{
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: -1;
   
  }

  .planet-text-medium span{
    height: 35px;
    margin-top: 120px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  .planet-text-small{
    border-radius: 50%;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .planet-text-small span{
    height: 35px;
    margin-top: 75px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }