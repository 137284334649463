.reuse-container{
    display: flex;
    flex-direction: row;
}

.reuse-boxes{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 30vw;
    height: 100vh;  
}

.reuse-hover-div-container{
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
}

.reuse-hover-div{
    background-color: #7BBEC3;
    color: white;
    border: 0.3vw solid white;
    border-radius: 2vw;
    padding-top: 1.5vw;
    padding-bottom: 2.5vw;
    position: relative;
    width: 13vw;
    display: flex;
    justify-content: center;
    z-index: 5;
}

.reuse-hover-div-2{
    background-color: #7BBEC3;
    color: white;
    border: 0.3vw solid white;
    border-radius: 3vw;
    padding-top: 2vw;
    padding-bottom: 3vw;
    position: relative;
    width: 13vw;
    display: flex;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    top: -8vw;
}



.reuse-hover-div-image{
    width: 80%;
}

.reuse-hover-div-full{
    background-size: cover;
    color: white;
    border: 0.3vw solid black;
    border-radius: 2vw;
    padding-top: 2vw;
    padding-bottom: 3vw;
    position: relative;
    width: 13vw;
    height: 6vw;
    display: flex;
    justify-content: center;
    z-index: 5;
    margin-top: 2vw;
}



.reuse-hover-div-image-full{
    width: 80%;
}

.reuse-watch-video-button{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
    padding: 0.2vw;
    position: absolute;
    width: 12.5vw;
    font-weight: bold;
    top: 10vw;
    text-align: center;
}

.reuse-box{
    background-color: #7BBEC3;
    color: white;
    border: 0.3vw solid white;
    font-size: 1vw;
    padding: 1vw;
    border-radius: 2vw;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    position: relative;

    &:hover{
          .reuse-hover-div-container{
            opacity: 1;
            pointer-events: all;
          }
          .reuse-hover-div-2{
            opacity: 1;
            pointer-events: all;
          }
    }
}

.reuse-heading{
    color: white;
    font-size: 2vw;
    font-weight: bold;
    text-align: left;
    display: flex;
    justify-content: center;
}

.reuse-heading-image{
    width: 2vw;
    transform: rotate(-90deg);
    margin-right: 2vw;
}

.reuse-fda-section{
    width: 30vw;
    height: 100%;
    margin-top: 5vw;
    margin-left: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover{
    .reuse-fda-hover-div{
opacity: 1;
pointer-events: all;
        }
    }
}

.reuse-process-section{
    width: 20vw;
    margin-left: 10vw;
    height: 100%;
    margin-top: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover{
        .reuse-process-hover-div{
    opacity: 1;
    pointer-events: all;
            }
        }
}

.reuse-fda-image{
    width: 100%;
}

.reuse-process-image{
    width: 100%;
}

.reuse-fda-hover-div{
    display: flex;
    flex-direction: column;
    gap:3.5vw;
    position: absolute;
    top: 2vw;
    left: 13vw;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.reuse-fda-video-button{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
    padding: 0.2vw;
    width: 12.5vw;
    font-weight: bold;
    text-align: center;
}








.reuse-process-hover-div{
    display: flex;
    flex-direction: column;
    gap:4.8vw;
    position: absolute;
    top: 10vw;
    left: 12vw;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.reuse-process-video-button-1{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
    padding: 0.5vw;
    width: 11vw;
    font-weight: bold;
    text-align: center;
}

.reuse-process-video-button-2{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
      padding: 0.5vw;
    width: 11vw;
    font-weight: bold;
    text-align: center;
    margin-left: -120%;
}

.reuse-process-video-button-3{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
      padding: 0.5vw;
    width: 11vw;
    font-weight: bold;
    text-align: center;
}

.reuse-process-video-button-4{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 0.8vw;
    border-radius: 3vw;
      padding: 0.5vw;
    width: 11vw;
    font-weight: bold;
    text-align: center;
    margin-left: -120%;
}




