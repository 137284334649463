.ep-chart{
    width: max(60vh, 63vw);
}

.ep-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ep-procedure{
    width: 90vw;
    display: flex;
    margin-top: 3vw;
}

.right-ep-heading{
    width: 27vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    text-align: right;
    font-weight: bold;
    color: white;
}

.right-ep-arrow{
    transform: rotate(90deg);
    width: 2vw;
    margin-left: 3vw;
}

.ep-text-boxes{
    display: flex;
    justify-content: space-around;
    width: 63vw;
    margin-top: 4vh;
}

.ep-text-box{
    text-align: left;
    border: 0.3vw solid white;
    background-color: #7BBEC3;
    font-size: 1vw;
    font-weight: bold;
    color: white;
    padding: 1.5vw;
    border-radius: 2.5vw;
    cursor: pointer;
  

    .ep-hover-image {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        border-radius: 2vw; /* Match the text box border radius */
        z-index: 2;
        backdrop-filter: blur(5px);
        background-color: rgba(0,0,0,0.6);
        padding: 20px;
        border: 3px solid white;
        pointer-events: none;
      }

      .ep-hover-image-1{
        width: 23vw;
        top:5vw;
        left: 8vw;
      }

      .ep-hover-div{
        position: absolute;
        top: 1vw;
        left: 38vw;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        border-radius: 3vw; /* Match the text box border radius */
        z-index: 2;
        pointer-events: none;
        backdrop-filter: blur(5px);
        background-color: rgba(0,0,0,0.6);
        border: 3px solid white;
        padding: 20px;
        height: 90vh;
      }

      .ep-hover-div-image{
        width: 35vw;
      }

      &:hover .ep-hover-image{
        opacity: 1;
        pointer-events: all;
      }

      &:hover .ep-hover-div{
        opacity: 1;
        pointer-events: all;
      }
}

.text-box-action{
    margin-top: 2vw;
    position: relative;
}

.ep-action-text-box-image{
    width: 15vw;
    border-radius: 2vw;
}

.ep-text-box-action{
    border: 0.3vw solid white;
    background-color: #7BBEC3;
    font-size: 1vw;
    font-weight: bold;
    color: white;
    padding: 1.5vw;
    border-radius: 2.5vw;
    cursor: pointer;
    position: absolute;
   left: 10vw;
    top: 2vw;
    text-align: left;
    width: 15vw;
}

.ep-pdf-button{
    background-color: white;
    border-radius: 3vw;
    border: 0.3vw solid black; 
    color: black;
    position: absolute;
    width: 15vw;
    padding: 0.5vw;
    font-size: 1vw;
    left: 11vw;
    top: 11.5vw;
    text-align: center;
    cursor: pointer;
}

