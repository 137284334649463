.collection-hover-content{
    position: absolute;
    left: -100px;
    top: -70px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

.collection-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.collection-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 250px;
text-align: left;
margin-top: -22px;
}

.collection-hover-desc{
    position: absolute;
    top: 150px;
    left: 80px;
}




//Purchasing Hover
.purchasing-hover-content{
    position: absolute;
    left: -110px;
    top: -110px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

.purchasing-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.purchasing-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 240px;
text-align: left;
margin-top: -22px;
}

.purchasing-hover-desc{
    position: absolute;
    top: 180px;
    left: 100px;
}

.purchasing-hover-image{
    position: absolute;
    left: 250px;
    top: 87px;
    width: 180px;
}



//Utilization Hover

.utilization-hover-content{
    position: absolute;
    left: -100px;
    top: -70px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

.utilization-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.utilization-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 250px;
text-align: left;
margin-top: -22px;
}

.utilization-hover-desc{
    position: absolute;
    top: 150px;
    left: 80px;
}

.utilization-hover-image{
    width: 200px;
    border-radius: 20px;
    position: absolute;
    top: 125px;
    left: -40px;
}

.utilization-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 200px;
    position: fixed;
    left: 10px;
    top: 230px;
}



//Reprocessing Hover
.reprocessing-hover-content{
    position: absolute;
    left: -110px;
    top: -110px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

.reprocessing-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.reprocessing-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 240px;
text-align: left;
margin-top: -22px;
}

.reprocessing-hover-desc{
    position: absolute;
    top: 180px;
    left: 100px;
}

.reprocessing-hover-image{
    position: absolute;
    left: 290px;
    top: 160px;
    width: 190px;
    border-radius: 20px;
}

.reprocessing-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 200px;
    position: fixed;
    left: 10px;
    top: 223px;
}


//Waste Hover

.waste-hover-content{
    position: absolute;
    left: -100px;
    top: -140px;
    opacity: 0;
    pointer-events: none;
    z-index: 3;
    transform: scale(0.8);
    text-shadow:none;
  }

.waste-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.waste-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 250px;
text-align: left;
margin-top: -22px;
text-transform: none !important;
}

.waste-hover-desc{
    position: absolute;
    top: 150px;
    left: 80px;
}

.waste-hover-image{
    width: 200px;
    border-radius: 20px;
    position: absolute;
    top: 90px;
    left: -40px;
}

.waste-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 200px;
    position: absolute;
    left: 30px;
    top: 130px;
}





//Emissions Hover
.emissions-hover-content{
    position: absolute;
    left: -110px;
    top: -200px;
    opacity: 0;
    pointer-events: none;
    z-index: 4;
    transform: scale(0.8);
    text-shadow:none;
  }

.emissions-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.emissions-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 240px;
text-align: left;
margin-top: -22px;
text-transform: none;
}

.emissions-hover-desc{
    position: absolute;
    top: 180px;
    left: 100px;
}

.emissions-hover-image{
    position: absolute;
    left: 290px;
    top: 160px;
    width: 190px;
    border-radius: 20px;
}

.emissions-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 200px;
    position: absolute;
    left: 20px;
    top: 155px;
}



//Reliable Hover
.reliable-hover-content{
    position: absolute;
    left: -110px;
    top: -200px;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    transform: scale(0.8);
    text-shadow:none;
  }

.reliable-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 220px;
text-align: center;
margin: 0 auto;
}

.reliable-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 240px;
text-align: left;
margin-top: -22px;
text-transform: none;
}

.reliable-hover-desc{
    position: absolute;
    top: 180px;
    left: 100px;
}

.reliable-hover-image{
    position: absolute;
    left: 290px;
    top: 140px;
    width: 190px;
    border-radius: 20px;
}

.reliable-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 200px;
    position: absolute;
    left: 20px;
    top: 150px;
}



//Cost Hover

.cost-hover-content{
    position: absolute;
    left: -100px;
    top: -140px;
    opacity: 0;
    pointer-events: none;
    z-index: 3;
    transform: scale(0.8);
    text-shadow:none;
  }

.cost-hover-heading{
background-color: #7bbec3;
color: white;
padding-bottom: 20px;
border-radius: 30px;
padding-top: 10px;
font-size: 20px;
font-weight: bold;
width: 200px;
text-align: center;
margin: 0 auto;
}

.cost-hover-text-content{
    background-color: #7bbec3;
color: white;
padding: 20px;
padding-right: 0px;
padding-left: 20px;
border-radius: 30px;
font-size: 16px;
font-weight: bold;
width: 250px;
text-align: left;
margin-top: -22px;
text-transform: none !important;
}

.cost-hover-desc{
    position: absolute;
    top: 150px;
    left: 80px;
}

.cost-hover-image{
    width: 200px;
    border-radius: 20px;
    position: absolute;
    top: 120px;
    left: -40px;
}

.cost-button{
    text-decoration: none;
    border-radius: 50rem;
    color: black;
    border: 2px solid black;
    background-color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    width: 220px;
    position: absolute;
    left: 30px;
    top: 170px;
}

