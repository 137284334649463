/* CubeRenderer.css */
.experience-container {
    width: 100%;
    height: 100vh;
    overflow: hidden; /* Ensures no scrollbars */
  }



  .canvas{
    position: fixed;
    left: 0px;
    top: 0px;
  }

  .top-arrow{
    z-index: -1;
  }

  .arrow-img{
    width: 25px;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  }


  .canvas-container{
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  

  