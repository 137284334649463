.reprocessing-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reprocessing-content{
    position: relative;
    width: 80vw;
    height: 75vh;
    display: flex;
    justify-content: flex-start;
}

.reprocessing-image{
    height: 30vw;
    border-radius: 3vw;
}

.reprocessing-text-box{
    background-color: #7BBEC3;
    color: white;
    font-size: 3vw;
    font-weight: bold;
    border: 0.3vw solid white;
    border-radius: 3vw;
    padding: 5vw 2vw;
    position: absolute;
    left: 30vw;
    top: 15vw;
    cursor: pointer;
   
    &:hover .reprocessing-hover-divs {
        opacity: 1;
        pointer-events: all;
      }

}

.reprocessing-hover-divs{
    position: absolute;
    left: 0%;
    top: -80%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 45vw;
    gap: 3vw;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0,0.6);
    padding: 3vw;
    padding-bottom: 4vw;
    border-radius: 3vw;
    border: 0.3vw solid white;
}

.reprocessing-hover-div{
    background-color: #7BBEC3;
    color: white;
    border: 0.3vw solid white;
    border-radius: 3vw;
    padding-top: 2vw;
    padding-bottom: 3vw;
    position: relative;
    flex:0 0 45%
}



.reprocessing-hover-div-image{
    width: 80%;
}

.reprocessing-watch-video-button{
    background-color: white;
    border: 0.3vw solid black;
    color: black;
    font-size: 1.2vw;
    border-radius: 3vw;
    padding: 0.8vw;
    position: absolute;
    width: 18vw;
}

