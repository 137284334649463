.video-modal{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center; 
    align-items: center;
    background-color: rgba(0,0,0,0.7);
}

.video-modal video{
max-width: 100vw;
}

.cross-button{
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 2;
    color: white;
    font-size: 50px;
    cursor: pointer;
}