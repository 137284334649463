.content-popup{
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(3px);
    z-index: 5;
}

.back-button{
    background-color: transparent;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px 30px;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}