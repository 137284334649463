.container {
    color: #ffffff;
    padding: 20px;
    position: relative;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  
    h1 {
      font-size: max(2vw, 25px);
      margin-bottom: 10px;
    }
  
    p {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
  }
  
  .text-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
  }
  
  .box-margin-top {
    margin-top: 35vh;
  }
  
  .text-box {
    border: 0.3vw solid white;
    background-color: #7BBEC3;
    border-radius: 3vw;
    font-size: 1vw;
    font-weight: bold;
    padding: 1.5vw 1.5vw;
    text-align: left;
    cursor: pointer;
    position: relative;
  
    .hover-image {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 3vw; /* Match the text box border radius */
      z-index: 2;
      backdrop-filter: blur(5px);
      background-color: rgba(0,0,0,0.2);
      padding: 20px;
      border: 3px solid white;
      pointer-events: none;
    }

    .hover-div{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        border-radius: 3vw; /* Match the text box border radius */
        z-index: 2;
        pointer-events: none;
        width: 100px;
    }

    .hover-div-image{
        width: 15vw;
    }

    .hover-div-button{
        background-color: white;
        border-radius: 2vw;
        font-size: 1vw;
        padding: 0.6vw 0.5vw;
        border: 0.2vw solid black;
        color: black;
        position: absolute;
        left: 13vw;
        top: 3vw;
        width: 20vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover .hover-div {
        opacity: 1;
        pointer-events: all;
      }

    .hover-image-1{
       width: 40vw;
       left: -2vw;
       top: -10vw;
    }

    .hover-image-2{
        width: 30vw;
        left: -10vw;
        top: -3vw;
     }

     .hover-image-3{
        border: none;
        backdrop-filter:none;
        background-color: transparent;
        padding: 0px;
        border-radius: 0px;
     }
  
    &:hover .hover-image {
      opacity: 1;
      pointer-events: all;
    }
  
    p {
      z-index: 1;
      position: relative;
    }
  }
  
  .flowchart-image {
    width: 40vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .bottom-heading {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: min(1.5rem, 1.5vw);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .bottom-heading-arrow {
    margin-top: 10px;
    width: 2vw;
  }
  