.start-screen-root {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.start-subheading {
    color: white;
    font-weight: bold;
    font-size: 2.5vw;
    margin-top: 5vh;
    text-shadow: 5px 4px 11px rgba(0, 0, 0, 0.34);
}

.start-heading {
    color: white;
    margin-top: 2vh;
    font-size: 6vw;
    font-weight: bold;
    text-shadow: 5px 4px 11px rgba(0, 0, 0, 0.34);
}

.start-instructions {
    color: white;
    font-size: 1.5vw;
    margin-top: 20vh;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.34);
    font-style: italic;
    font-weight: bold;
}

.start-instructions-2{
    margin-top: 3vh;
}

.enter-button {
    background-color: #408E40;
    color: white;
    font-size: 3.5vw;
    margin-top: 10vh;
    font-weight: bold;
    padding: 0.5vw 2vw 1vw 2vw;
    cursor: pointer;
    box-shadow: 5px 4px 11px rgba(0, 0, 0, 0.34);
}

.loader {
    width: 5vw;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #514b82;
    -webkit-mask: radial-gradient(circle closest-side at 50% 40%, #0000 94%, #000);
    transform-origin: 50% 40%;
    animation: l25 1s infinite linear;
    position: fixed;
    left: calc(50% - 2.5vw);
    top: calc(50% - 2.5vw);
}

@keyframes l25 {
    100% {
        transform: rotate(1turn);
    }
}
